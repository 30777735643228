.townPage {
  text-align: center;
  margin: 5px;
}

.townSpace {
  margin-top: 20px;
}

.buttonSpace {
  display: flex;
  justify-content: space-evenly;
}
