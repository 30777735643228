.person {
  margin: 15px;
  padding: 8% 0;
}

.person[open] {
  padding: 0;
}

.personDetails {
  text-align: left;
  font-size: small;
  font-weight: bold;
}
