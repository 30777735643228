.missingPage {
  height: 80vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border: 5px solid grey;
  margin: 20px 100px;
  border-radius: 15px;
  background: rgb(255, 254, 189);
}

.missingPage h1 {
  color: rgb(41, 0, 0);
}

.missingPage img {
  height: 300px;
}
