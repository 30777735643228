.town {
  font-size: larger;
}

.population {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.peopleLoader {
  width: 1207px;
  height: 743px;
  border: 7px solid grey;
  background: lightyellow;
  border-radius: 15px;
  column-count: 2;
  margin-bottom: 70px;
}

.townDetails {
  margin-bottom: 30px;
  font-weight: bolder;
}

.townDetails p {
  font-weight: normal;
}
