@import "~antd/dist/antd.css";

.ant-menu-item-active {
  color: red;
}

.header {
  display: flex;
}
.header h2 {
  width: 75%;
}

body {
  background-image: url(./assets/page-background.jpg);
}

.navbar {
  display: flex;
  justify-content: space-between;
  font-size: larger;
  text-align: left;
}
.navbar h1 {
  color: #930c10;
  padding-top: 10px;
}

.link {
  margin: 5px;
}

textarea {
  resize: none;
}

.savedTownsDiv {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border: 1px solid #930c10;
}

.userProfileDiv {
  display: flex;
}
.userProfileDiv h1 {
  margin-right: 25px;
}

.loginDIv {
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.loginWrapper {
  text-align: center;
  margin-top: 20px;
}
.loginWrapper h1 {
  color: #930c10;
}

.loginBlock {
  border: 1px solid #930c10;
  padding: 30px;
}
.registerBlock {
  border: 1px solid #930c10;
  padding: 30px;
}
